<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'listings', query: this.$route.query }"
          >Listings</router-link
        >
      </v-toolbar-title>
    </v-app-bar>
    <v-tabs hide-slider class="listing-info-tabs">
      <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
        {{ item.tab }}
      </v-tab>

      <v-tab-item class="col-md-12 col-sm-12 col-xs-12 mt-6 pa-0">
        <ListingForm />
      </v-tab-item>

      <v-tab-item class="col-md-12 col-sm-12 col-xs-12 mt-6 pa-0">
        <div class="mt-12 mx-4" id="review-list">
          <v-simple-table>
            <thead>
              <tr>
                <th width="20%">Review Author</th>
                <th width="20%">Title</th>
                <th width="30%">Description</th>
                <th width="15%">Rating</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="record in reviews"
                :key="record.id"
                @click="viewBookingDetails(record.reviewable_id)"
              >
                <td>
                  <user-avatar
                    :avatar="record.author.avatar"
                    size="40"
                    class="mr-4"
                  />
                  <span :class="{ 'red--text': record.author.deleted_at }">{{
                    record.author.full_name
                  }}</span>
                </td>
                <td>
                  {{ record.title }}
                </td>
                <td>
                  {{ record.description }}
                </td>
                <td>
                  {{ record.rating }}
                </td>
              </tr>

              <template v-if="loading">
                <tr v-for="index in 1" :key="index + '-skeleton'">
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
          <div v-if="reviewslListMeta.total" class="py-5 d-flex justify-center">
            <small class="grey--text lighten-4">
              {{ reviewslListMeta.from }}-{{ reviewslListMeta.to }} of
              {{ reviewslListMeta.total | formatNumber }}
            </small>
          </div>
        </div>
        <div
          class="text-center mt-2 mb-8"
          v-if="reviewslListMeta.last_page > 1"
        >
          <v-pagination
            @input="pageChanged"
            :value="reviewslListMeta.current_page"
            :length="reviewslListMeta.last_page"
            :total-visible="10"
            circle
          ></v-pagination>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiAlertCircleOutline, mdiCheck, mdiChevronLeft } from '@mdi/js'
import ListingForm from './components/Form'
import UserAvatar from '@/components/UserAvatar'

export default {
  name: 'ListingEdit',

  components: {
    ListingForm,
    UserAvatar,
  },

  data() {
    return {
      loading: false,
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
      tabItems: [{ tab: 'Info' }, { tab: 'Reviews' }],
    }
  },

  created() {
    this.clearRecordList()
    this.fetchReviews(1)
  },

  computed: {
    ...mapState({
      reviews: (state) => state.review.list,
      reviewslListMeta: (state) => state.review.listMeta,
    }),
  },

  methods: {
    ...mapActions({
      getReviews: 'review/getRecords',
    }),

    ...mapMutations({
      clearRecordList: 'review/clearList',
    }),

    pageChanged(page) {
      this.fetchReviews(page)
    },

    async fetchReviews(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: '-created_at',
        id: this.$route.params.id,
        limit: 20,
      }

      this.loading = true
      await this.getReviews(params)
      this.loading = false
    },

    viewBookingDetails(bookingId) {
      let routeData = this.$router.resolve({
        name: 'booking.edit',
        params: { id: bookingId },
      })
      window.open(routeData.href, '_blank')
    },
  },

  filters: {
    formatNumber(value) {
      return Number(value).toLocaleString()
    },
  },
}
</script>

<style Scoped>
table > tbody > tr:hover td {
  background: #eeeeee !important;
  cursor: pointer;
}
</style>
