<template>
  <div class="py-8" v-if="listing">
    <!-- add v-if="form" -->
    <div class="ma-4" v-if="form">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        {{ displayName }}
      </div>

      <v-alert
        dense
        outlined
        type="warning"
        color="orange darken-3"
        max-width="400px"
        v-if="listing && !!listing.disabled_at"
      >
        This listing is <strong>disabled</strong>. <br />
        Reason: {{ listing.disable_reason }}
      </v-alert>

      <div class="d-flex my-6">
        <v-form ref="form" class="listing-form">
          <label class="text-field-label">Title</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.title"
            name="title"
            :error-messages="form.$getError('title')"
            :loading="loading"
          ></v-text-field>

          <!-- Text Area -->
          <label class="text-field-label">Description</label>
          <v-textarea
            filled
            name="description"
            v-model="form.description"
            :error-messages="form.$getError('description')"
            :loading="loading"
          ></v-textarea>

          <label class="text-field-label">Sub-Category</label>
          <v-select
            filled
            :items="subCategories"
            item-text="label"
            v-model="form.category_id"
            item-value="id"
            name="category_id"
            :error-messages="form.$getError('category_id')"
          />

          <!-- calendar availabilities here -->
          <label class="text-field-label">Unavailabilities</label>
          <div>
            <v-date-picker
              multiple
              no-title
              v-model="form.unavailabilities"
              name="unavailabilities[]"
            ></v-date-picker>
          </div>

          <!-- gallery here -->
          <label class="text-field-label">Photos</label>
          <v-file-input
            multiple
            label="Upload photos"
            v-model="newPhotos"
            @change="addPhotos"
            v-on:click:clear="false && clearFormPhotos"
            :error-messages="form.$getError('photos')"
            accept="image/*"
            class="mb-2"
          ></v-file-input>
          <gallery :images="form.photos" @remove="deletePhoto" />
          <!-- end of gallery -->

          <label class="text-field-label">Location</label>
          <!-- <v-textarea
            v-if="selectedAddress.length > 0"
            v-model="selectedAddress"
            rows="3"
            max-rows="6"
            disabled
          ></v-textarea> -->
          <div v-if="selectedCity.length > 0">
            <v-textarea
              rows="3"
              :value="generateInputValue"
              disabled
            ></v-textarea>
            <a @click="handleClean()">
              <v-icon>{{ icons.clear }}</v-icon>
            </a>
          </div>
          <location-field
            v-else
            :id="form.location_places_id"
            :text="locationText"
            :error-messages="form.$getError('location_places_id')"
            @change="setLocation"
            disabled
          />
          <div>
            <v-progress-circular
              v-if="loadingLocation"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <div v-else class="mx-auto mb-4">
              <v-list>
                <v-list-group :value="mainToogle">
                  <template v-slot:activator>
                    <v-list-item-title
                      @click="selectCountry('Australia')"
                      :checked="countryName.includes('Australia')"
                      >Australia</v-list-item-title
                    >
                    <!-- <v-checkbox @click="isSelected('Australia')"></v-checkbox> -->
                  </template>

                  <v-list-group
                    v-for="(state, stateIndex) in statesList"
                    :key="'state-' + stateIndex"
                    :value="false"
                    no-action
                    sub-group
                    class="padding-left"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title
                          class="padding-left"
                          @click="selectState(state.state_name)"
                          :checked="selectedState.includes(state.state_name)"
                          >{{ state.state_name }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <!-- state.regions -->
                    <v-list-group
                      v-for="(region, regionIndex) in state.regions"
                      :key="'region-' + regionIndex"
                      :value="false"
                      no-action
                      sub-group
                      class="padding-left"
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title
                            @click="selectRegion(region.region_name)"
                            :checked="
                              selectedRegion.includes(region.region_name)
                            "
                            >{{ region.region_name }}</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>

                      <v-list-group
                        class="padding-left"
                        v-for="(area, areaIndex) in region.areas"
                        :key="'area-' + areaIndex"
                        :value="false"
                        no-action
                        sub-group
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title
                              class="padding-left"
                              @click="selectArea(area.area_name)"
                              :checked="selectedArea.includes(area.area_name)"
                              >{{ area.area_name }}</v-list-item-title
                            >
                          </v-list-item-content>
                          <!-- <v-checkbox
                            @click="selectData(area.area_name)"
                          ></v-checkbox> -->
                        </template>

                        <v-list-group
                          class="padding-left"
                          :value="false"
                          no-action
                          sub-group
                        >
                          <template class="padding-left" v-slot:activator>
                            <v-list-item-content>
                              <v-list-item-title>Cities</v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <v-radio-group>
                            <v-list-item
                              class="padding-left"
                              v-for="(city, cityIndex) in area.cities"
                              :key="'city-' + cityIndex"
                              link
                            >
                              <v-list-item-content>{{
                                city.city_name
                              }}</v-list-item-content>

                              <v-radio
                                :value="city.city_name"
                                @click="selectData(city.city_name)"
                                :checked="selectedCity.includes(city.city_name)"
                              ></v-radio>
                            </v-list-item>
                            <!-- <v-list-item
                              v-for="(city, cityIndex) in area.cities"
                              :key="'city-' + cityIndex"
                              link
                            >
                              <v-list-item-content>{{
                                city.city_name
                              }}</v-list-item-content>

                              <v-radio
                                :value="city.city_name"
                                @click="selectData(city.city_name)"
                              ></v-radio>
                            </v-list-item> -->
                          </v-radio-group>
                        </v-list-group>
                      </v-list-group>
                    </v-list-group>
                  </v-list-group>
                </v-list-group>
              </v-list>
            </div>
          </div>

          <label class="text-field-label">Price</label>
          <!-- Daily Added -->
          <div class="mt-4">
            <div class="d-flex align-items-center">
              <!-- <v-checkbox
                  label="Daily"
                  v-model="showTextFieldDaily"
                  :value="form.price > 0"
                ></v-checkbox> -->
              <!-- <h4 v-if="form.price > 0"> -->
              Daily Price &nbsp;&nbsp;&nbsp; $ {{ form.price }}
              <!-- </h4>
              <h4 v-else>Daily Price &nbsp;&nbsp;&nbsp; $ 0</h4> -->
              <div class="main_area">
                <input
                  type="checkbox"
                  class="checkbox_hidden"
                  v-model="showTextFieldDaily"
                  @keypress="isNumber($event)"
                />
                <v-icon>{{ icons.pen }}</v-icon>
              </div>

              <!-- <a> {{ icon.pen }}</a> -->
            </div>
            <v-text-field
              :class="{ 'active-input1': showTextFieldDaily }"
              flat
              solo
              required
              class="mt-2"
              v-model="form.price"
              :error-messages="form.$getError('price')"
              name="price"
              :loading="loading"
              prefix="$"
              @keypress="isNumber($event)"
            ></v-text-field>
          </div>

          <div class="my-4">
            <div class="d-flex align-items-center">
              <!-- <h4 v-if="form.price_per_week > 0"> -->
              Weekly Price &nbsp;&nbsp;&nbsp; $ {{ form.price_per_week }}
              <!-- </h4>
              <h4 v-else>Weekly Price &nbsp;&nbsp;&nbsp; $ 0</h4> -->
              <div class="main_area">
                <input
                  type="checkbox"
                  class="checkbox_hidden"
                  v-model="showTextFieldWeekly"
                />
                <v-icon>{{ icons.pen }}</v-icon>
              </div>

              <!-- <a> {{ icon.pen }}</a> -->
            </div>
            <!-- <v-text-field
              v-if="showTextFieldWeekly"
              flat
              solo
              required
              class="mt-2"
              v-model="form.price_per_week"
              :value="form.price_per_week"
              :error-messages="form.$getError('price_per_week')"
              name="price_per_week"
              :loading="loading"
              prefix="$"
              @keypress="isNumber($event)"
            ></v-text-field> -->
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model.number="form.price_per_week"
              :error-messages="form.$getError('price_per_week')"
              name="price_per_week"
              :loading="loading"
              prefix="$"
              @keypress="isNumber($event)"
              :class="{ 'active-input': showTextFieldWeekly }"
            ></v-text-field>
          </div>

          <div class="mb-4">
            <div class="d-flex align-items-center">
              <!-- <h4 v-if="form.price_per_month > 0"> -->
              Monthly Price &nbsp;&nbsp;&nbsp; $ {{ form.price_per_month }}
              <!-- </h4>
              <h4 v-else>Monthly Price &nbsp;&nbsp;&nbsp; $ 0</h4> -->
              <div class="main_area">
                <input
                  type="checkbox"
                  class="checkbox_hidden"
                  v-model="showTextFieldMonthly"
                />
                <v-icon>{{ icons.pen }}</v-icon>
              </div>
            </div>
            <v-text-field
              :class="{ 'active-input2': showTextFieldMonthly }"
              flat
              solo
              required
              class="mt-2"
              v-model="form.price_per_month"
              :error-messages="form.$getError('price_per_month')"
              name="price_per_month"
              :loading="loading"
              prefix="$"
              @keypress="isNumber($event)"
            ></v-text-field>
          </div>

          <label class="text-field-label">Min Days To Book an Item</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.minimum_booking_days"
            :error-messages="form.$getError('minimum_booking_days')"
            name="minimum_booking_days"
            :loading="loading"
            @keypress="isNumber($event)"
          ></v-text-field>

          <label class="text-field-label">Bond</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.bond"
            :error-messages="form.$getError('bond')"
            name="bond"
            :loading="loading"
            @keypress="isNumber($event)"
          ></v-text-field>

          <label class="text-field-label">Quantity</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            v-model="form.quantity"
            :error-messages="form.$getError('quantity')"
            name="quantity"
            :loading="loading"
            @keypress="isNumber($event)"
            @copy.prevent
            @paste.prevent
          ></v-text-field>

          <label class="text-field-label">Condition</label>
          <v-select
            filled
            :items="conditions"
            item-text="text"
            item-value="value"
            v-model="form.condition"
            :error-messages="form.$getError('condition')"
            name="condition"
          />

          <label class="text-field-label">Special Instructions</label>
          <v-textarea
            filled
            name="special_instructions"
            v-model="form.special_instructions"
            :error-messages="form.$getError('special_instructions')"
            :loading="loading"
          ></v-textarea>

          <!--          <v-checkbox-->
          <!--            :input-value="listing && listing.disabled_at"-->
          <!--            label="Disable Listing"-->
          <!--            color="orange darken-3"-->
          <!--            class="mt-0"-->
          <!--            @mousedown="showDisableDiaglog = true"-->
          <!--          ></v-checkbox>-->

          <div>
            <div class="mt-2">
              <v-btn
                color="error"
                outlined
                class="mr-4 px-6"
                height="40px"
                width="100%"
                @click="showDeleteDialog = true"
                >Delete</v-btn
              >
            </div>

            <v-btn
              color="primary"
              class="mt-3"
              height="40px"
              width="100%"
              @click="updateListing"
              :loading="form.$busy"
            >
              <v-icon>{{ icons.save }}</v-icon>
              Save Changes
            </v-btn>
          </div>
        </v-form>
      </div>

      <v-overlay absolute :value="loading" opacity="0.2">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
        {{ snackbar.message }}
        <v-btn text @click="snackbar.show = false">OK</v-btn>
      </v-snackbar>
    </div>

    <!-- Disable modal -->
    <confirm-modal
      v-model="showDisableDiaglog"
      title="Disable Listings"
      :message="disableMessage"
      @cancel="showDisableDiaglog = false"
      @confirm="disable"
      confirm-color="error"
    >
      <template v-slot:content v-if="!listing.disabled_at">
        <v-text-field
          label="Input reason (Optional)"
          v-model="disableReason"
        ></v-text-field>
      </template>
    </confirm-modal>

    <confirm-modal
      v-model="showDeleteDialog"
      title="Delete Listing"
      message="Are you sure you want to delete this listing?"
      confirm-color="error"
      @cancel="showDeleteDialog = false"
      @confirm="deleteListing"
    />
  </div>
</template>

<script>
import { mdiContentSave, mdiPencil, mdiDeleteCircle } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import Form from '@/utils/form'
import { pick, get, flatMapDeep, sortBy } from 'lodash'
import LocationField from './LocationField'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Gallery from './Gallery'
import Snackbar from '@/utils/mixins/Snackbar'

export default {
  name: 'ListingForm',
  mixins: [Snackbar],

  components: {
    LocationField,
    Gallery,
    ConfirmModal,
  },

  data() {
    return {
      form: null,
      loading: false,
      loadingLocation: false,
      showTextFieldDaily: true,
      showTextFieldWeekly: true,
      showTextFieldMonthly: true,
      mainToogle: false,
      countryName: [],
      statesList: [],
      selectedCity: [], // To store the selected city
      selectedRegion: [], // To store the selected city
      selectedArea: [], // To store the selected city
      selectedState: [], // To store the selected city
      selectedAddress: [],
      icons: {
        save: mdiContentSave,
        pen: mdiPencil,
        clear: mdiDeleteCircle,
      },
      conditions: [
        {
          text: 'Brand New',
          value: 'brand_new',
        },
        {
          text: 'Like New',
          value: 'like_new',
        },
        {
          text: 'Used Excellent',
          value: 'used_excellent',
        },
        {
          text: 'Used Good',
          value: 'used_good',
        },
      ],
      showDisableDiaglog: false,
      disableReason: '',
      showDeleteDialog: false,
      newPhotos: [],
      deletedPhotos: [],
    }
  },

  created() {
    this.fetchCategories()
    this.getListing()
    this.getLocationData()
  },

  computed: {
    ...mapState({
      listing: (state) => state.listing.listingDetails,
      categories: (state) => state.category.list,
      location: (state) => state.location.locationDetails,
    }),
    // showTextFieldDaily() {
    //   return this.form.price > 0
    // },
    generateInputValue() {
      // Concatenate the values into a single string
      return [
        this.countryName,
        this.selectedState,
        this.selectedRegion,
        this.selectedArea,
        this.selectedCity,
      ].filter(Boolean)
      // .join(', ')
    },
    displayName() {
      return this.listing.title
    },

    locationText() {
      return this.listing.location ? this.listing.location.full_name : ''
    },

    disableMessage() {
      return `Are you sure you want to ${this.disableAction} this listing?`
    },

    disableAction() {
      return this.listing && this.listing.disabled_at ? 'enable' : 'disable'
    },

    subCategories() {
      let subCategories = flatMapDeep(this.categories, function (category) {
        return category.subcategories
      })

      return sortBy(subCategories, ['label'])
    },
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories',
      getListingDetails: 'listing/getListingDetails',
      updateListingDetails: 'listing/updateListing',
      disableListing: 'listing/disableListing',
      getLocationListing: 'listing/getLocationListing',
    }),

    selectArea(areaName) {
      if (this.selectedArea.includes(areaName)) {
        this.selectedArea = this.selectedArea.filter(
          (area) => area !== areaName
        )
      } else {
        this.selectedArea = [areaName]
      }
    },
    selectRegion(regionName) {
      if (this.selectedRegion.includes(regionName)) {
        this.selectedRegion = this.selectedRegion.filter(
          (region) => region !== regionName
        )
      } else {
        this.selectedRegion = [regionName]
      }
    },
    selectState(stateName) {
      if (this.selectedState.includes(stateName)) {
        this.selectedState = this.selectedState.filter(
          (state) => state !== stateName
        )
      } else {
        this.selectedState = [stateName]
        // this.selectedAddress = this.selectedState
      }
    },
    selectData(cityName) {
      if (this.selectedCity.includes(cityName)) {
        this.selectedCity = this.selectedCity.filter(
          (city) => city !== cityName
        )
      } else {
        this.selectedCity = [cityName]
        // this.selectedAddress = this.selectedCity
      }
    },

    selectCountry(countryName) {
      if (this.countryName.includes(countryName)) {
        this.countryName = this.countryName.filter(
          (city) => city !== countryName
        )
      } else {
        this.countryName = [countryName]
      }
    },
    handleClean() {
      this.selectedCity = []
      this.mainToogle = false
    },
    isNumber(evt) {
      const keysAllowed = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
      ]
      const keyPressed = evt.key

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    // async getLocationData() {
    //   try {
    //     this.loading = true
    //     await this.getLocationListing()
    //       .then(() => {
    //         var SN = this.$store.state.listing.listingDetails.state
    //         this.$store.state.listing.addressData.states.filter((stateName) => {
    //           stateName.state_name === SN
    //           console.log(stateName.state_name === SN)
    //         })
    //         // console.log(
    //         //   'AddresAPI',
    //         //   this.$store.state.listing.addressData.states
    //         // ) // this.statesList = this.$store.state.listing.addressData.states
    //         this.loading = false
    //       })
    //       .catch((err) => {
    //         this.loading = false
    //         console.log(err)
    //       })
    //   } catch (error) {
    //     console.error('Error fetching location data:', error)
    //   }
    // },
    // async getLocationData() {
    //   try {
    //     this.loading = true
    //     await this.getLocationListing()
    //       .then(() => {
    //         // this.statesList = this.$store.state.listing.addressData.states
    //         var SN = this.$store.state.listing.listingDetails.state
    //         // Filter the state name
    //         const filteredState =
    //           this.$store.state.listing.addressData.states.find(
    //             (stateName) => stateName.state_name === SN
    //           )

    //         // Check if the filtered state exists
    //         if (filteredState) {
    //           // Iterate through this.statesList to find the index of the matched state
    //           const matchedStateIndex =
    //             this.$store.state.listing.addressData.states.findIndex(
    //               (state) => {
    //                 console.log(filteredState.state_name, state.state_name)
    //                 state.state_name.toString() ===
    //                   filteredState.state_name.toString()
    //                 console.log(state.state_name === filteredState.state_name)
    //               }
    //             )
    //           console.log('>>>>>>>>', matchedStateIndex)
    //           // If the matched state index is found, set its value to true
    //           if (matchedStateIndex !== -1) {
    //             // this.statesList = this.$store.state.listing.addressData.states
    //             this.statesList[matchedStateIndex].value = true
    //           }
    //         }
    //         this.loading = false
    //       })
    //       .catch((err) => {
    //         this.loading = false
    //         console.log(err)
    //       })
    //   } catch (error) {
    //     console.error('Error fetching location data:', error)
    //   }
    // },

    // async getLocationData() {
    //   try {
    //     this.loading = true
    //     await this.getLocationListing()
    //       .then(() => {
    //         var SN = this.$store.state.listing.listingDetails.state
    //         const filteredState =
    //           this.$store.state.listing.addressData.states.find(
    //             (stateName) => stateName.state_name === SN
    //           )
    //         // Check if the filtered state exists
    //         if (filteredState) {
    //           // Iterate through this.statesList to find the index of the matched state
    //           const matchedStateIndex =
    //             this.$store.state.listing.addressData.states.findIndex(
    //               (state) => state.state_name === filteredState.state_name
    //             )
    //           // If the matched state index is found, set its value to true
    //           if (matchedStateIndex !== -1) {
    //             this.statesList = this.$store.state.listing.addressData.states
    //             console.log('111111', this.statesList[matchedStateIndex])
    //             this.$set(this.statesList[matchedStateIndex], 'value', true)
    //           }
    //         }
    //         this.loading = false
    //       })
    //       .catch((err) => {
    //         this.loading = false
    //         console.log(err)
    //       })
    //   } catch (error) {
    //     console.error('Error fetching location data:', error)
    //   }
    // },

    async getLocationData() {
      try {
        this.loadingLocation = true
        await this.getLocationListing()
          .then(() => {
            this.loadingLocation = false
            // setTimeout(() => {
            // }, 10000)
            this.statesList = this.$store.state.listing.addressData.states
            // var SN = this.$store.state.listing.listingDetails.state
            // // console.log('State name from store:', SN)
            // const filteredState =
            //   this.$store.state.listing.addressData.states.find(
            //     (stateName) => stateName.state_name === SN
            //   )
            // // console.log('Filtered state:', filteredState)

            // // Check if the filtered state exists
            // if (filteredState) {
            //   // Iterate through this.statesList to find the matched state
            //   const matchedState =
            //     this.$store.state.listing.addressData.states.find(
            //       (state) => state.state_name === filteredState.state_name
            //     )
            //   // console.log('Matched state:', matchedState)

            //   // If the matched state is found, set its value to true
            //   if (matchedState) {
            //     this.statesList = this.$store.state.listing.addressData.states
            //     matchedState.value = true

            //     var AR = this.$store.state.listing.listingDetails.areas
            //     console.log(
            //       'Area name from store:',
            //       matchedState.regions[0].areas
            //     )
            //     const filteredArea = matchedState.regions[0].areas.find(
            //       (stateName) => stateName.area_name === AR
            //     )
            //     console.log('Filtered Area:', filteredArea)

            //     // Check if the filtered state exists
            //     if (filteredArea) {
            //       // Iterate through this.statesList to find the matched state
            //       const matchedArea = matchedState.regions[0].areas.find(
            //         (state) => state.area_name === filteredArea.area_name
            //       )
            //       console.log('Matched Area:', matchedArea)

            //       // If the matched state is found, set its value to true
            //       if (matchedArea) {
            //         this.stateList.state.regions = matchedArea.regions[0].areas
            //         matchedArea.value = true
            //         // this.stateList =
            //         //   this.$store.state.listing.addressData.states
            //       }
            //     }
            //   }
            // }
            // this.loading = false
          })
          .catch(() => {
            this.loadingLocation = false
          })
      } catch (err) {
        this.loadingLocation = false
      }
    },

    disable() {
      this.form.$busy = true
      let action = this.disableAction

      this.disableListing({
        id: this.listing.id,
        action,
        reason: this.disableReason,
      })
        .then(() => {
          this.form.$busy = false
          this.showDisableDiaglog = false
          this.showSnackbar(`Listing successfully ${action}!`, 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          this.showDisableDiaglog = false
          if (err.response.status === 422) {
            this.showSnackbar(`Failed to ${action} listing!`, 'error')
          }
        })
    },

    async updateListing() {
      this.form.$busy = true
      this.form.$clearErrors()

      let formData = this.prepareData()
      this.updateListingDetails(formData)
        .then(() => {
          this.form.$busy = false
          this.showSnackbar('Listing details successfully updated!', 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    deleteListing() {
      this.form.$busy = true

      this.listing
        .delete()
        .then(() => {
          this.showMainSnackbar('Listing deleted successfully!', 'success')
          this.$router.replace('/listings')
        })
        .catch((e) => {
          this.showSnackbar(get(e, 'response.data.message'), 'error')
        })
        .finally(() => {
          this.form.$busy = false
          this.showDeleteDialog = false
        })
    },

    async getListing() {
      this.loading = true
      await this.getListingDetails(this.$route.params.id)
      this.initForm()
      this.loading = false
    },

    initForm() {
      this.form = new Form(
        pick(this.listing, [
          'id',
          'title',
          'description',
          'category_id',
          'location_places_id',
          'location',
          'price',
          'price_per_week',
          'price_per_month',
          'minimum_booking_days',
          'bond',
          'quantity',
          'special_instructions',
          'condition',
          'unavailabilities',
          'photos',
        ])
      )

      this.form.unavailabilities = this.listing.unavailabilities.map(
        (item) => item.date
      )
    },

    async fetchCategories() {
      if (this.loading) return
      let params = {
        sort: 'label',
      }

      this.loading = true
      await this.getCategories(params)
      this.loading = false
    },

    setLocation(id) {
      // console.log(locationListing)
      this.form.location_places_id = id
    },

    addPhotos(photos) {
      this.form.photos = this.form.photos.concat(photos)

      this.newPhotos = []
    },

    deletePhoto(photo, ndx) {
      if (!(photo instanceof File)) {
        this.deletedPhotos.push({
          id: photo.id,
          delete: true,
        })
      }

      // listing must have at least 1 image
      // remove image from the gallery
      this.form.photos.splice(ndx, 1)
    },

    prepareData() {
      let formData = new FormData(this.$refs.form.$el)
      formData.id = this.form.id

      this.appendPhotosField(formData)

      // set unavailabilities
      this.form.unavailabilities.forEach((value) => {
        formData.append('unavailabilities[]', value)
      })

      return formData
    },

    appendPhotosField(formData) {
      // add photos to formdata
      let newPhotosCount = 0

      this.form.photos.concat(this.deletedPhotos)

      this.form.photos.forEach((photo, index) => {
        newPhotosCount++
        if (photo instanceof File) {
          formData.append(`photos[${index}]`, photo)
        }
      })

      // to be deleted photos
      this.deletedPhotos.forEach((photo, index) => {
        let photoIndex = newPhotosCount + index
        formData.append(`photos[${photoIndex}][id]`, photo.id)
        formData.append(`photos[${photoIndex}][delete]`, true)
      })
    },
  },

  watch: {
    $route() {
      this.getListing()
    },
  },
}
</script>

<style lang="scss" scoped>
.listing-form {
  width: 400px;

  @media (max-width: 420px) {
    width: 100%;
  }

  .v-text-field {
    max-width: 25rem;
  }
}
.align-items-center {
  align-items: center;
  justify-content: space-between;
}
.align-items-center span {
  margin: 0 20px;
}
.main_area {
  position: relative;
}
.checkbox_hidden {
  position: absolute;
  right: 24px;
  width: 19px;
  height: 28px;
  opacity: 0;
  z-index: 99;
  cursor: pointer !important;
}

.theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.38);
}
.padding-left {
  padding-left: 8px;
}
.active-input,
.active-input2,
.active-input1 {
  display: none;
}
</style>
